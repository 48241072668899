import React from "react";

import OurWorkCard from "./OurWorkCard";
import { projectsCategories } from "../configs/ourProjectsConfig";
import { useTranslation } from "react-i18next";

const getCardBackgroundColor = (idx) => {
  if (idx === 0) {
    return "bg-gradient-to-tr from-blue-600 to-cyan-400 mb-0";
  }
  if (idx === 1) {
    return "bg-gradient-to-tr from-yellow-300 to-amber-500 mb-0 lg:mb-[-100px]";
  }
  if (idx === 2) {
    return "bg-gradient-to-tr from-lime-300 to-green-600 mb-0";
  }
  if (idx === 3) {
    return "bg-gradient-to-tr from-purple-600 to-fuchsia-400 mb-0 lg:mb-[-100px]";
  }
};

function OurWorkComponent({ title, className }) {
  const { t: translate } = useTranslation();

  const filteredWorkConfig = projectsCategories.filter(
    (category) => category.value && category.image
  );
  return (
    <div className={`${className} max-w-7xl mx-auto `}>
      <h2 className="text-3xl md:text-3xl lg:text-4xl drop-shadow mb-[60px] font-bold text-center">
        {title}
      </h2>

      <div className=" flex flex-row flex-wrap justify-around">
        {filteredWorkConfig.map((work, idx) => {
          return (
            <div
              key={work.value}
              className="flex flex-col lg:basis-1/4 justify-center mb-5 md:mb-7 items-center"
            >
              <OurWorkCard
                page={work.page}
                category={work.value}
                icon={work.image}
                workTitle={work.label}
                description={translate(work.description)}
                descriptions={work.descriptions}
                cardWhiteTextColor={!(idx === 1 || idx === 2)}
                className={getCardBackgroundColor(idx)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default OurWorkComponent;
