import React, { useState } from "react";
import ReactPlayer from "react-player";
import AccordionDescription from "./AccordionDescription";
import { useTranslation } from "react-i18next";

const Accordion = ({ items, initialOpenedItem = 0 }) => {
  const { t: translate } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(initialOpenedItem);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="space-y-4">
      {items.map((item, index) => (
        <div key={index} className="border rounded-lg overflow-hidden">
          <button
            className="w-full text-left p-4 bg-gray-100 hover:bg-gray-200 focus:outline-none"
            onClick={() => toggleAccordion(index)}
          >
            <h2 className="font-bold">{item.title}</h2>
          </button>
          <div
            className={`transition-[max-height] duration-500 ease-in-out overflow-hidden ${
              activeIndex === index ? "max-h-screen" : "max-h-0"
            }`}
          >
            <div className="p-4 bg-white border-t">
              {item.video && (
                <div className="w-full mb-4">
                  <ReactPlayer url={item.video} width={"100%"} controls />
                </div>
              )}
              <AccordionDescription
                descriptions={item.descriptions}
                showLessLabel={translate("text_show_less")}
                showMoreLabel={translate("text_show_more")}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
