import React from "react";
import { toAbsoluteUrl } from "../../helpers/assetHelper";
import KTSVG from "../../utils/KTSVG";

function HeroVideoComponent({
  height,
  wrapperClassName,
  title,
  description,
  videoUrl,
}) {
  const scrollHalfScreenDown = () => {
    const fullScreenHeight = window.innerHeight;
    const currentScroll = window.scrollY;
    const totalPageHeight = document.documentElement.scrollHeight;

    const remainingScroll = totalPageHeight - currentScroll - fullScreenHeight;

    const scrollDistance = Math.min(fullScreenHeight, remainingScroll);

    if (scrollDistance > 0) {
      window.scrollBy({
        top: scrollDistance,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      className={`${wrapperClassName} w-full relative overflow-hidden bg-cover bg-[50%] bg-no-repeat`}
      style={{ height: height }}
    >
      <video
        autoPlay
        loop
        muted
        playsInline
        poster={toAbsoluteUrl("/assets/2aFarmPhotos/_SF_0145.jpg")}
        style={{
          height: height,
          width: "100%",
          objectFit: "cover",
          background: "white",
        }}
      >
        <source src={videoUrl} type="video/mp4" />
      </video>

      <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-gray-700 bg-fixed opacity-40" />
      <div className="flex flex-col justify-center items-center absolute bottom-0 left-0 right-0 top-0 text-white h-full w-full">
        <h1 className="max-w-7xl mx-auto text-4xl md:text-5xl lg:text-6xl drop-shadow mb-5 font-bold text-center">
          {title}
        </h1>
        {description && (
          <p className="max-w-2xl mx-auto text-center text-sm lg:text-base drop-shadow font-medium mb-10 md:px-16">
            {description}
          </p>
        )}
      </div>
      <div className="flex absolute w-full bottom-4 items-center justify-center text-center">
        <button
          className="rounded-full bg-white p-2 cursor-pointer bg-opacity-90	"
          onClick={scrollHalfScreenDown}
        >
          <KTSVG path={toAbsoluteUrl("assets/down-arrow.svg")} />
        </button>
      </div>
    </div>
  );
}

export default HeroVideoComponent;
