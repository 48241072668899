import React, { useState } from "react";

function AccordionDescription({
  descriptions,
  showMoreLabel = "Show More",
  showLessLabel = "Show Less",
}) {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => setShowAll(!showAll);

  const visibleDescriptions = showAll ? descriptions : descriptions.slice(0, 3);

  return (
    <div>
      <div className={"overflow-hidden"}>
        {visibleDescriptions.map((description, index) => (
          <p key={index} className="text-gray-700 mb-2">
            {description}
          </p>
        ))}
      </div>
      {descriptions.length > 3 && (
        <button
          onClick={toggleShowAll}
          className="text-blue-500 hover:underline focus:outline-none mt-2"
        >
          {showAll ? showLessLabel : showMoreLabel}
        </button>
      )}
    </div>
  );
}

export default AccordionDescription;
