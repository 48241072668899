import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { toAbsoluteUrl } from "../helpers/assetHelper";

function SliderWithText({ title, sliderData, className, imageClassName }) {
  return (
    <div className={`select-none max-w-full  ${className}`}>
      <h2 className="text-primary-color text-center text-xl lg:text-2xl xl:text-3xl font-bold">
        {title}
      </h2>
      <div className="max-w-7xl mx-auto py-[30px]">
        <Swiper
          modules={[Autoplay]}
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          effect="fade"
          loop={true}
          draggable
        >
          {sliderData &&
            sliderData.map((img, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <div className="flex justify-center items-center h-[250px] md:h-[400px]">
                    <img
                      alt={`marketing-${idx}`}
                      className={`h-full ${imageClassName}`}
                      src={toAbsoluteUrl(img)}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
}

export default SliderWithText;
