import React from "react";

import "../lang/i18n";

import OurWorkComponent from "../components/OurWorkComponent";
import CompanyNumbers from "../components/CompanyNumbers";
import { toAbsoluteUrl } from "../helpers/assetHelper";
import HeroComponent from "../components/PagesComponents/HeroComponent";
import { useTranslation } from "react-i18next";
import CustomHero from "../components/PagesComponents/CustomHero";
import SliderTechnologies from "../components/SliderTechnologies";
import ApproachCustomSoftwareComponent from "../components/ApproachCustomSoftwareComponent";
import HalfBgWithText from "../components/PagesComponents/HalfBgWithText";
import MarketingSectionComponent from "../components/MarketingSectionComponent";

function Home() {
  const { t: translate } = useTranslation();

  return (
    <div className="font-montserrat min-h-screen mt-[70px]">
      <div
        className="bg-gradient-to-tr from-gray-600 to-gray-600 w-full m-auto
        bg-center bg-cover bg-no-repeat
        z-10 relative
        before:content-['']
        before:absolute
        before:inset-0
        before:block
        before:bg-gradient-to-tr
        before:from-gray-800
        before:to-gray-800
        before:opacity-30
        before:z-[-5]"
        style={{
          backgroundImage: "url(assets/SSNTAssets/hero-picture.jpg)",
          backgroundColor: "red",
        }}
      >
        <CustomHero
          height={400}
          title="SOFTWARE SOLUTIONS"
          subtitle="NETWORK TOOLS"
          backgroundImage="transparent"
        />
        <OurWorkComponent className="pt-[20px] pb-[200px] px-2" />
      </div>

      <SliderTechnologies />

      <CompanyNumbers
        height={350}
        backgroundImage={toAbsoluteUrl("/assets/SSNTAssets/company.jpg")}
      />

      <ApproachCustomSoftwareComponent
        title={translate("approach_section_title")}
      />

      <HalfBgWithText
        height={440}
        title={translate("estimate_project_section_title")}
        description={translate("estimate_project_section_description")}
        buttonText={translate("estimate_project_section_button")}
        buttonUrl="contact"
        image={"/assets/SSNTAssets/estim3.png"}
        contentClassName="bg-primary-color"
        buttonVariant="secondary"
        wrapperClassName="mb-[180px] sm:mb-0 "
      />

      <MarketingSectionComponent
        title={"marketing_steps_section_title"}
        sectionImage="/assets/SSNTAssets/marketing.jpg"
      />

      <HeroComponent
        height={300}
        backgroundImage={toAbsoluteUrl("/assets/SSNTAssets/keyboard2.jpg")}
        wrapperClassName="bg-top bg-fixed before:from-primary-light-color before:to-primary-color"
        title="SSNT"
      />
    </div>
  );
}

export default Home;
